import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Animate,
  AuthContext,
  Row,
  useAPI,
  Loader,
  Card,
  Grid,
  Image,
  Button,
  Message,
  ViewContext,
} from "components/lib";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { SubNav } from "components/lib";
import jsPDF from "jspdf";
import logo from "../../assets/IdentityCheck_Logo_RGB_Purple.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
const Settings = require("settings.json");

export function VerificationView(props) {
  const params = useParams();
  const [imageDataFetched, setImageDataFetched] = useState(false);
  const [imagesResponse, setImgagesResponse] = useState([]);
  const [navBarItems, setNavBarItems] = useState([]);
  const [verificationData, setVerificationData] = useState({});

  const verification = useAPI(`/api/verification/${params.id}`);
  const accountInfo = useAPI(`/api/account`);
  const authContext = useContext(AuthContext);
  const context = useContext(ViewContext);
  const copy = <FontAwesomeIcon icon={faPaste} />;
  const publicUrl = `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://identity.stackgo.io"
  }/public/verification/${verification?.data?.verification?.[0].id}`;

  let images = [];
  useEffect(() => {
    async function getVerificationData() {
      if (!verification?.data?.images) {
        return;
      }

      verification.data.images.forEach(async function (image) {
        const mResponse = await Axios.get(`/api/veriff/media/${image.id}`);

        images.push({
          ...mResponse.data?.data,
          name: image.name,
          id: image.id,
        });
        setImgagesResponse(images);
        if (images.length === verification.data.images.length) {
          setImageDataFetched(true);
        }
      });
    }

    function settingVerificationData() {
      if (Object.keys(verificationData).length > 0) {
        return;
      }
      let updatedVerificationData = {};
      if (verification?.data?.verification?.[0]?.type.includes("full_auto")) {
        console.log(
          "Full Auto Verification",
          verification?.data?.verification?.[0].result_info?.data
        );
        updatedVerificationData = {
          documentType:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.document?.type?.value || "No Document",
          email: verification?.data?.verification?.[0].email || "No Email",
          result: verification?.data?.verification?.[0].result || "No Result",
          ip:
            verification?.data?.verification?.[0].result_info?.technicalData
              ?.ip || "No IP",
          documentFirstName:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.person?.firstName?.value || "No Name",
          documentLastName:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.person?.lastName?.value || "No Name",
          documentIdNumber:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.person?.idNumber?.value || "No ID Number",
          dateOfBirth:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.person?.dateOfBirth?.value || "No DOB",
          documentNumber:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.document?.number?.value || "No Document",
          documentCountry:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.document?.country?.value || "NA",
          validFrom:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.document?.validFrom?.value || "NA",
          validUntil:
            verification?.data?.verification?.[0].result_info?.data
              ?.verification?.document?.validUntil?.value || "NA",
          decisionTime:
            verification?.data?.verification?.[0].result_info?.acceptanceTime ||
            "NA",
        };
        console.log("Full Auto Verification Data", updatedVerificationData);
      } else {
        updatedVerificationData = {
          documentType:
            verification?.data?.verification?.[0].result_info?.verification
              ?.document?.type || "No Document",
          email: verification?.data?.verification?.[0].email || "No Email",
          result: verification?.data?.verification?.[0].result || "No Result",
          ip:
            verification?.data?.verification?.[0].result_info?.technicalData
              ?.ip || "No IP",
          documentFirstName:
            verification?.data?.verification?.[0].result_info?.verification
              ?.person?.firstName || "No Name",
          documentLastName:
            verification?.data?.verification?.[0].result_info?.verification
              ?.person?.lastName || "No Name",
          documentIdNumber:
            verification?.data?.verification?.[0].result_info?.verification
              ?.person?.idNumber || "No ID Number",
          dateOfBirth:
            verification?.data?.verification?.[0].result_info?.verification
              ?.person?.dateOfBirth || "No DOB",
          documentNumber:
            verification?.data?.verification?.[0].result_info?.verification
              ?.document?.number || "No Document",
          documentCountry:
            verification?.data?.verification?.[0].result_info?.verification
              ?.document?.country || "NA",
          validFrom:
            verification?.data?.verification?.[0].result_info?.verification
              ?.document?.validFrom || "NA",
          validUntil:
            verification?.data?.verification?.[0].result_info?.verification
              ?.document?.validUntil || "NA",
          decisionTime:
            verification?.data?.verification?.[0].result_info?.verification
              ?.decisionTime || "NA",
        };
      }
      setVerificationData(updatedVerificationData);
    }

    async function setUpNavBarItems() {
      const verificationInfo = verification.data.verification[0];
      console.log("Setting up nav bar items", verificationInfo);
      let updatedNavBarItems = [];

      if (verificationInfo.sanctions_results) {
        updatedNavBarItems.push(
          {
            label: "KYC",
            link: `/verification/${params.id}`,
          },
          {
            label: "Sanctions",
            link: `/verification/sanctions/${params.id}`,
          }
        );
      }

      if (verificationInfo.adverse_media_results) {
        updatedNavBarItems.push({
          label: "Adverse Media",
          link: `/verification/adverse/${params.id}`,
        });

        if (updatedNavBarItems.length === 1) {
          updatedNavBarItems = [
            {
              label: "KYC",
              link: `/verification/${params.id}`,
            },
            ...updatedNavBarItems,
          ];
        }
      }

      if (verificationInfo.response_id) {
        updatedNavBarItems.push({
          label: "Response",
          link: `/verification/${params.id}/response/${verificationInfo.response_id}`,
        });
      }

      if (verificationInfo?.additional_checks?.length > 0) {
        updatedNavBarItems.push({
          label: "US Criminal Checks",
          link: `/verification/us-criminal-v1/${params.id}`,
        });
      }

      setNavBarItems(updatedNavBarItems);
    }

    if (!verification.loading && verification.data) {
      getVerificationData();
      settingVerificationData();
      setUpNavBarItems();
    }
  }, [verification]);

  function downloadPDF() {
    console.log("Download PDF!");
    var doc = new jsPDF("portrait", "px", "a4", "false");
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.addImage(logo, "PNG", 310, 30, 100, 15);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text(50, 40, `Identity Verification Report`);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    doc.text(
      50,
      50,
      `Session: ${verification?.data?.verification?.[0].session_id}`
    );
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(50, 90, "Report Summary");
    doc.setFontSize(12);
    doc.text(50, 110, "Identity");
    doc.setFont("helvetica", "normal");
    doc.text(50, 125, "First Name");
    doc.text(
      170,
      125,
      `${
        verificationData.documentFirstName
      }`
    );
    doc.text(50, 140, "Last Name");
    doc.text(
      170,
      140,
      `${
        verificationData.documentLastName
      }`
    );
    doc.text(50, 155, `Date of Birth`);
    doc.text(
      170,
      155,
      `${
        verificationData.dateOfBirth
      }`
    );
    doc.setFont("helvetica", "bold");
    doc.text(50, 180, "Verification Result");
    doc.setFont("helvetica", "normal");
    doc.text(50, 195, "Result");
    doc.text(
      170,
      195,
      `${verification?.data?.verification?.[0].result}` || "No Result"
    );

    doc.text(50, 210, "Timestamp");

    doc.text(
      170,
      210,
      verificationData.decisionTime.split("T")[0] || "NA"
    );

    doc.text(50, 225, "Service");
    doc.text(
      170,
      225,
      `Biometric Face and Document Identity Check ${
        verification?.data?.verification?.[0].type.includes("full_auto")
          ? "(Full Auto)"
          : ""
      }`
    );

    doc.text(50, 240, "Liveness test");
    doc.text(170, 240, "Passed");

    doc.text(50, 255, "Data and Device integrity");
    doc.text(170, 255, "Passed");

    doc.setFont("helvetica", "bold");
    doc.text(50, 280, "Prepared for");
    doc.setFont("helvetica", "normal");

    doc.text(50, 295, "Business Name");
    doc.text(
      170,
      295,
      authContext?.user?.accounts?.filter(
        (a) => (a.id = authContext?.user?.account_id)
      )?.[0]?.name || "No Business"
    );
    doc.setFont("helvetica", "bold");
    doc.text(50, 320, "Document");
    doc.setFont("helvetica", "normal");
    doc.text(50, 335, "Type");
    doc.text(
      170,
      335,
      verificationData.documentType
    );
    doc.text(50, 350, "Country");
    doc.text(
      170,
      350,
      verificationData.documentCountry
    );
    doc.text(50, 365, "Number");
    doc.text(
      170,
      365,
      verificationData.documentNumber
    );
    doc.text(50, 380, "Valid From");
    doc.text(
      170,
      380,
      verificationData.validFrom
    );
    doc.text(50, 395, "Valid To");
    doc.text(
      170,
      395,
      verificationData.validUntil
    );

    doc.setFont("helvetica", "bold");
    doc.text(50, 420, "Session");
    doc.setFont("helvetica", "normal");
    doc.text(50, 435, "IP Address");
    doc.text(
      170,
      435,
      verificationData.ip
    );
    doc.text(50, 450, "IP Location");
    doc.text(
      170,
      450,
      verification?.data?.verification?.[0].result_info?.technicalData
        ?.location || "No Location"
    );
    doc.text(50, 465, "Longitude");
    doc.text(
      170,
      465,
      `${verification?.data?.verification?.[0].result_info?.technicalData?.longitude}` ||
        "No Longitude"
    );

    doc.text(50, 480, "Latitude");
    doc.text(
      170,
      480,
      `${verification?.data?.verification?.[0].result_info?.technicalData?.latitude}` ||
        "No Latitude"
    );

    // doc.text(50, 495, "OS");
    // doc.text(
    //   170,
    //   495,
    //   verification?.data?.verification?.[0].result_info?.technicalData?.os ||
    //     "No OS"
    // );

    // doc.text(50, 510, "Browser");
    // doc.text(
    //   170,
    //   510,
    //   verification?.data?.verification?.[0].result_info?.technicalData
    //     ?.browser || "No Browser"
    // );

    // doc.text(50, 525, "Device");
    // doc.text(
    //   170,
    //   525,
    //   verification?.data?.verification?.[0].result_info?.technicalData
    //     ?.device || "No Device"
    // );

    if (verification?.data?.verification?.[0].type === "kyc+sanctions") {
      if (
        verification?.data?.verification?.[0].sanctions_results?.found_records
          .length === 0
      ) {
        doc.setFont("helvetica", "bold");
        doc.text(50, 555, "Sanctions Results");
        doc.setFont("helvetica", "normal");
        doc.text(170, 555, "No Matches Found");
      } else {
        {
          verification?.data?.verification?.[0].sanctions_results.found_records.map(
            (r, i) => (
              <div key={i}>
                {doc.addPage()}
                {doc.setFont("helvetica", "bold")};
                {doc.text(50, 40, "Sanctions Results")};
                {doc.setFont("helvetica", "normal")};
                {doc.text(50, 80, "Last Names")}
                {doc.text(170, 80, r?.last_names?.toString())}
                {doc.text(50, 95, "Given Names")}
                {doc.text(170, 95, r?.given_names?.toString())}
                {doc.text(50, 110, "Alias Names")}
                {doc.text(170, 110, r?.alias_names?.toString())}
                {doc.text(50, 125, "DoB")}
                {doc.text(170, 125, r?.date_of_birth?.toString())}
                {doc.text(50, 140, "Place of Birth")}
                {doc.text(170, 140, r?.place_of_birth?.toString())}
                {doc.text(50, 155, "Name")}
                {doc.text(170, 155, r?.name)}
                {doc.text(50, 170, "Source")}
                {doc.text(170, 170, r?.source_id)}
              </div>
            )
          );
        }
      }
    }

    doc.setFont("Courier-Bold", "bold");
    {
      imagesResponse?.map((image, i) => {
        const widthRatio = (pageWidth - 80) / image.width;
        const heightRatio = (pageHeight - 100) / image.height;
        // console.log("width ratio", widthRatio);
        // console.log("height ratio", heightRatio);

        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        return (
          <div key={i}>
            {console.log("image", i, image.width, image.height)}
            {doc.addPage()}
            {doc.addImage(logo, "PNG", 310, 30, 100, 15)}
            {doc.setFont("helvetica", "bold")}
            {doc.setFontSize(16)}
            {doc.text(50, 40, `Identity Verification Report`)}
            {doc.setFontSize(9)}
            {doc.setFont("helvetica", "normal")}
            {doc.text(
              50,
              50,
              `Session: ${verification?.data?.verification?.[0].session_id}`
            )}
            {doc.setFontSize(12)}
            {doc.setFont("helvetica", "bold")}
            {doc.text(50, 75, image.name)}
            {doc.setFont("helvetica", "normal")}
            {doc.text(50, 90, "Date Supplied")}
            {doc.text(
              170,
              90,
              `${
                verificationData.decisionTime.split(
                      "T"
                    )[0]
              }` || "NA"
            )}
            {doc.addImage(
              `data:image/png;base64,${image.image}`,
              "PNG",
              50,
              105,
              ratio * image.width,
              ratio * image.height
            )}
          </div>
        );
      });
    }

    doc.save(`Identity Verification Report`);
  }

  function getSanctionsResultHtml(sanctionsResults) {
    if (sanctionsResults.found_records.length === 0) {
      return <p>Sanctions Results: No matches found</p>;
    } else {
      const sanctionsMarkupArray = sanctionsResults.found_records.map((r) => (
        <p>
          Last Names: {r?.last_names?.toString()}
          <br />
          Given Names: {r?.given_names?.toString()}
          <br />
          Alias Names: {r?.alias_names?.toString()}
          <br />
          DoB: {r?.date_of_birth?.toString()}
          <br />
          Place of Birth: {r?.place_of_birth?.toString()}
          <br />
          Name: {r?.name}
          <br />
          Source: {r?.source_id}
          <br />
          <br />
        </p>
      ));

      const sanctionsMarkup = [];
      sanctionsMarkup.push(
        <div>
          <br />
          <p>
            Sanctions Results: <strong>Possible Matches found</strong>
          </p>
        </div>
      );

      sanctionsMarkupArray.forEach((sanction, index) => {
        sanctionsMarkup.push(<div key={index}>{sanction}</div>);
      });

      return sanctionsMarkup;
    }
  }

  return !imageDataFetched &&
    verification.loading &&
    verification.data === undefined &&
    imagesResponse.data === undefined &&
    images.length === 0 ? (
    <Loader></Loader>
  ) : (
    <Fragment>
      <Animate type="pop">
        <Row color="brand" title="Verification Result">
          {" "}
        </Row>

        <Card>
          {console.log("Full response: ", verification)}
          {console.log(verification.data?.images)}
          {console.log("imageDataFetched: ", imageDataFetched)}
          {console.log("imagesResponse: ", imagesResponse)}

          <Card
            title={`Verification for ${verification?.data?.verification?.[0].first_name} ${verification?.data?.verification?.[0].last_name}`}
          >
            <SubNav items={navBarItems} />

            <p>
              <Button
                icon="download"
                action={downloadPDF}
                text="Download PDF"
              />
            </p>
            <br />

            {verification?.data?.verification?.[0].active && (
              <Message>
                Verification is active. Link: {publicUrl}{" "}
                <span
                  className="sg-icon-copy"
                  onClick={() => {
                    navigator.clipboard.writeText(publicUrl);
                    context.notification.show("Copied!", "success", true);
                  }}
                >
                  {copy}
                </span>
              </Message>
            )}

            <p>Document Type: {verificationData.documentType}</p>
            <p>Email: {verificationData.email}</p>
            <p>Result: {verificationData.result}</p>
            <p>IP: {verificationData.ip}</p>
            <p>Document First Name: {verificationData.documentFirstName}</p>
            <p>Document Last Name: {verificationData.documentLastName}</p>
            <p>Document ID Number {verificationData.documentIdNumber}</p>
            <p>Date Of Birth: {verificationData.dateOfBirth}</p>
            <p>Document Number: {verificationData.documentNumber}</p>
            <p>Document Country: {verificationData.documentCountry}</p>
            <p>Valid From: {verificationData.validFrom}</p>
            <p>Valid Until: {verificationData.validUntil}</p>
            <p>Decision Time: {verificationData.decisionTime}</p>
            {verification?.data?.verification?.[0].type.includes(
              "full_auto"
            ) && (
              <>
                <p>Check Type: Full Auto</p>
                <p>
                  Decision Score: {verification?.data?.verification?.[0].result_info?.data?.verification?.decisionScore}
                </p>
              </>
            )}
            <br />
            {accountInfo?.data?.sanctions_settings?.address_extraction && (
              <>
                <h2>Address Extraction</h2>
                <p>
                  Parsed Address:{" "}
                  {verification?.data?.verification?.[0]?.result_info
                    ?.verification?.person?.addresses?.[0]?.fullAddress ||
                    "No Address Found"}
                </p>
              </>
            )}
            {console.log("FUll response: ", verification)}
            {console.log(verification.data?.images)}
            {console.log("imagesResponse: ", imagesResponse)}

            {imagesResponse === null ||
            verification.data === undefined ||
            imageDataFetched === false ? (
              <Animate type="pop">
                <Row color="white" loading={verification?.loading}>
                  <Card title="Images associated to your verifications will appear here" />
                </Row>
              </Animate>
            ) : (
              <div>
                {console.log("imagesResponse: ", imagesResponse)}
                {imagesResponse?.map((image, i) => (
                  <Card key={i}>
                    <Image
                      key={i}
                      source={`data:image/png;base64,${image.image}`}
                      title={verification.data.images[i].name}
                    />
                    <p>{image.name}</p>
                  </Card>
                ))}

                {/* <img src={`data:image/jpeg;base64,${mediaResponse.data?.image}`} /> */}
              </div>
            )}
          </Card>
        </Card>
      </Animate>
    </Fragment>
  );
}
